import { useState, useEffect } from 'react';

export function Tickets(props) {

  const [text, recordResult] = useState('Call was not executed');

  const executeCall = () => {
    fetch('http://vira/backend/ajax.php')
      .then(res => res.text())
      .then(result => {
        recordResult(result);
      });
  };

  useEffect(executeCall);

  return <div>
  <span className="text-danger">{text}</span>
  </div>;
}
