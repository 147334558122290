import batches from '../batches.json';



export function Batches() {
  function checkAllCompanies() {
    console.log('Checking all companies...');
  }

  function uncheckAllCompanies() {
    console.log('Unchecking all companies...');
  }

  const bchs=batches;
  return (
    <div>

    <div>
    <h3>Batches overview</h3>
    <table className="table">
    <tbody>
      <tr>
        <th>Id</th>
        <th>RefID</th>
        <th>Status</th>
        <th>Item count</th>
        <th>Created</th>
        <th>Changed</th>
        <th>Company</th>
        <th>Company refID</th>
        <th>Format</th>
        <th>Filename</th>
        <th>Description</th>
        <th>Author</th>
        <th>Processed</th>
      </tr>
      {
        Object.keys(bchs).map(key => {
          const batch = bchs[key];
          return <tr key={key}>
            <td>{key}</td>
            <td>{batch.refID}</td>
            <td>{batch.status}</td>
            <td>{batch.itemCount}</td>
            <td>{batch.created}</td>
            <td>{batch.changed}</td>
            <td>{batch.company}</td>
            <td>{batch.companyRefID}</td>
            <td>{batch.format}</td>
            <td>{batch.filename}</td>
            <td>{batch.description}</td>
            <td>{batch.author}</td>
            <td>{batch.processed}</td>
          </tr>;
        })
      }
    </tbody>
    </table>
    </div>

    <div id="filters-block">
      <div id="filters-hook">Filters</div>
      <div className="filters-list">

        <div className="btn-group-vertical p-1" role="group">
          <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-danger" htmlFor="btncheck1">ERROR</label>

          <input type="checkbox" className="btn-check" id="btncheck2" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-danger" htmlFor="btncheck2">DELIVERY_ERROR</label>

          <input type="checkbox" className="btn-check" id="btncheck3" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-danger" htmlFor="btncheck3">VALIDATION_ERROR</label>
        </div>

        <div className="btn-group-vertical p-1" role="group">
          <input type="checkbox" className="btn-check" id="btncheck12" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-primary" htmlFor="btncheck12">APPROVED</label>

          <input type="checkbox" className="btn-check" id="btncheck13" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-primary" htmlFor="btncheck13">PREPARED</label>

          <input type="checkbox" className="btn-check" id="btncheck14" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-primary" htmlFor="btncheck14">PENDING_DELIVERY</label>

          <input type="checkbox" className="btn-check" id="btncheck15" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-primary" htmlFor="btncheck15">RECEIVED</label>

          <input type="checkbox" className="btn-check" id="btncheck16" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-primary" htmlFor="btncheck16">PARSING</label>
        </div>

        <div className="btn-group-vertical p-1" role="group">
          <input type="checkbox" className="btn-check" id="btncheck4" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-success" htmlFor="btncheck4">TEST</label>

          <input type="checkbox" className="btn-check" id="btncheck5" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-success" htmlFor="btncheck5">CREATED</label>
        </div>

        <div className="btn-group-vertical p-1" role="group">
          <input type="checkbox" className="btn-check" id="btncheck6" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck6">PENDING_APPROVAL</label>

          <input type="checkbox" className="btn-check" id="btncheck7" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck7">PENDING_ATTACHMENTS</label>

          <input type="checkbox" className="btn-check" id="btncheck8" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck8">PENDING_RECEIPT</label>

          <input type="checkbox" className="btn-check" id="btncheck9" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck9">PENDING_RECEIPT_L2</label>

          <input type="checkbox" className="btn-check" id="btncheck10" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck10">PENDING_AGGREGATE</label>

          <input type="checkbox" className="btn-check" id="btncheck11" autoComplete="off"/>
          <label className="btn btn-sm btn-outline-warning" htmlFor="btncheck11">PARTIALLY_ACCEPTED</label>
        </div>

      </div>

      <div className="p-1">
        <div className="companies-options">
          <div className="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group me-2" role="group" aria-label="First group">
              <button type="button" className="btn btn-outline-secondary" onClick={() => checkAllCompanies()}>All</button>
              <button type="button" className="btn btn-outline-secondary" onClick={() => uncheckAllCompanies()}>None</button>
              <button type="button" className="btn btn-outline-secondary">All sys</button>
              <button type="button" className="btn btn-outline-secondary">Except sys</button>
            </div>
            <div className="input-group">
              <div className="input-group-text" id="btnGroupAddon">Search for company</div>
              <input type="text" className="form-control" aria-describedby="btnGroupAddon"/>
            </div>
          </div>
        </div>
        <div className="companies-list">
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 1</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 1</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 222</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company with a very very very very very long name</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 4</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 555</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 6</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 7</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 8</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 9</button>
          <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-toggle="button" autoComplete="off">Company 0</button>
        </div>
      </div>
    </div>

    </div>
  );
}
