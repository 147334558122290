import React from 'react';
import batches from '../batches.json';

export class BatchesNew extends React.Component{

  constructor(props){
    super(props);

    this.setSearchString=this.setSearchString.bind(this);
    this.handleKeyPress=this.handleKeyPress.bind(this);
    this.deleteBatch=this.deleteBatch.bind(this);
    this.saveComment=this.saveComment.bind(this);
    this.handleCommentEdit=this.handleCommentEdit.bind(this);

    this.displayRowsOptions=[50, 100, 250];

    this.state={
      isLoading: true,
      fullScreen: false,
      displayRows: this.displayRowsOptions[0],
      batches: null,
      opts: {
        search: '',
        order_field: 'date',
        order_vector: 'desc',
        deleted: false
      }
    };
  }

  toggleFullScreen(){
    const elem = document.getElementById('batches-window');

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        return false;
      });
      this.setState({
        fullScreen: true
      });
    }
    else {
      this.setState({
        fullScreen: false
      }, () => document.exitFullscreen());
    }
  }

  toggleDeleted(){
    const newValue=!this.state.opts.deleted;
    this.setState(prevState => (
      {
        opts: {
          ...prevState.opts,
          deleted: newValue
        }
      }
    ), this.loadBatches());
  }

  loadBatches(){
    this.setState({
      isLoading: true
    }, () => {
      // const url='index.php?entryPoint=batches&todo=loadBatches';
      // const data = new FormData();
      // data.append('opts', JSON.stringify(this.state.opts));
      // fetch(url, { method: 'POST', body: data })
      // .then(res => res.json())
      // .then(result => {
      //   console.log(result);
      //   if (result.success===1) this.setState({
      //     isLoading: false,
      //     batches: result.batches
      //   });
      //   else alert('Loading failed with message: '+result.message);
      // });
      this.setState({
        isLoading: false,
        batches: batches
      });
    });
  }

  componentDidMount(){
    this.loadBatches();
  }

  setSearchString(e){
    this.setState(prevState => (
      {
        opts: {
          ...prevState.opts,
          search: e.target.value
        }
      }
    ));
  }

  handleKeyPress(e){
    if (e.code==='Enter') this.loadBatches();
  }

  deleteBatch(id, batch_id){
    if (window.confirm(`Delete batch #${batch_id}?`)) {
      const url='index.php?entryPoint=batches&todo=deleteBatch&id='+id;
      fetch(url)
      .then(res => res.json())
      .then(result => {
        if (result.success===1) this.loadBatches();
        else alert('Deleting batch failed with message: '+result.message);
      });
    }
  }

  saveComment(id){
    if (window.confirm(`Save comment?`)) {
      const url = 'index.php?entryPoint=batches&todo=saveComment&id='+id;
      const comment = this.state.batches[id].comment;
      const data = new FormData();
      data.append('comment', comment.trim());
      fetch(url, { method: 'POST', body: data })
      .then(res => res.json())
      .then(result => {
        // console.log(result);
      });
    }
  }

  handleCommentEdit(id, text){
    this.setState(prevState => (
      {
        batches: {
          ...prevState.batches,
          [id]: {
            ...prevState.batches[id],
            comment: text
          }
        }
      }
    ));
  }

  setDisplayRows(amount){
    this.setState({
      displayRows: amount
    });
  }

  render(){
    const totalIDs=this.state.isLoading ? [] : Object.keys(this.state.batches);
    const displayIDs=this.state.isLoading ? [] : Object.keys(this.state.batches).slice(0, this.state.displayRows);
    return <div id="batches-window">
    <h1>Batches</h1>
    <div className="btn-toolbar align-items-center" role="toolbar">
      <div className="btn-group me-2" role="group">
        <button type="button" className={"btn btn-secondary"+(this.state.isLoading ? " active processing" : "")} onClick={()=> {if (!this.state.isLoading) this.loadBatches()}}><i className="bi bi-arrow-repeat"></i></button>
      </div>

      <div className="btn-group me-2" role="group">
        <input type="text" className="form-control" placeholder="Search" onChange={this.setSearchString} onKeyPress={this.handleKeyPress} />
      </div>

      <div className="btn-group me-2" role="group">
        {
          this.displayRowsOptions.map(val => (
            val===this.state.displayRows
            ? <button key={`display_rows_${val}`} type="button" className={"btn btn-secondary active"}>{val}</button>
            : <button key={`display_rows_${val}`} type="button" className={"btn btn-secondary"} onClick={() => this.setDisplayRows(val)}>{val}</button>
          ))
        }
      </div>

      <div className="btn-group me-2" role="group">
        <button type="button" className={"btn btn-secondary"+(this.state.opts.deleted ? " active" : "")} onClick={() => this.toggleDeleted()}>Deleted</button>
        <button type="button" className="btn btn-secondary" data-toggle="collapse" data-target="#batches-raw" aria-expanded="false" aria-controls="batches-raw">Raw</button>
        {
          this.state.fullScreen
          ? <button type="button" className="btn btn-secondary active" onClick={()=>this.toggleFullScreen()}><i className="bi bi-arrows-angle-contract"></i></button>
          : <button type="button" className="btn btn-secondary" onClick={()=>this.toggleFullScreen()}><i className="bi bi-arrows-angle-expand"></i></button>
        }
      </div>

      <div className="text-muted">&nbsp;
        {
          this.state.isLoading
          ? <React.Fragment>Loading items...</React.Fragment>
          : <React.Fragment>Displaying {displayIDs.length} of total {totalIDs.length}</React.Fragment>
        }
      </div>
    </div>

    <div className="collapse" id="batches-raw">
      <pre>{JSON.stringify(this.state.batches)}</pre>
    </div>

    {
      <BatchesTable
        batches={this.state.batches}
        displayIDs={displayIDs}
        deleteBatch={this.deleteBatch}
        saveComment={this.saveComment}
        handleCommentEdit={this.handleCommentEdit}
        deleted={this.state.opts.deleted}
      />
    }
    </div>;
  }
}

function BatchesTable(props) {
  const batches = props.batches;
  const displayIDs = props.displayIDs;
  return (
    <table className="table">
    <thead>
    <tr>
    <th>Batch ID</th>
    <th>Ref ID</th>
    <th>Status</th>
    <th>Direction</th>
    <th>Items</th>
    <th>Company</th>
    <th>Company Ref ID</th>
    <th>Format</th>
    <th></th>
    </tr>
    </thead>
    <tbody>
    {
      displayIDs.map((id) => {
        const batch = batches[id];
        return <tr key={id}>
        <td>{batch.batch_id}</td>
        <td>{batch.ref_id}</td>
        <td>{batch.status}</td>
        <td>{batch.direction}</td>
        <td>{batch.item_count}</td>
        <td>{batch.company_name}</td>
        <td>{batch.company_refid}</td>
        <td>{batch.format}</td>
        <td>
        {
          /*<BatchControl
          id={id}
          batch_id={batch.batch_id}
          filename={batch.filename}
          deleteBatch={this.props.deleteBatch}
          comment={batch.comment}
          saveComment={this.props.saveComment}
          handleCommentEdit={this.props.handleCommentEdit}
          deleted={this.props.deleted}
          />*/
        }
        </td>
        </tr>;
      })
    }
    </tbody>
    </table>
  );
}
