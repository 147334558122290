import './App.css';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ReactComponent as Logo } from './images/Logo_Symbol_Logo_Symbol.svg';
import { Splash } from './components/Splash';
import { Home } from './components/Home';
import { Tickets } from './components/Tickets';
import { Batches } from './components/Batches';
import { BatchesNew } from './components/BatchesNew';
import { DDDDs } from './components/DDDDs';

function App() {
  return (
    <Router>
      <Splash />
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">

          <Link to="/" className="navbar-brand">
            <Logo id="logo" />
            Virtual Assistant
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/batches" className="nav-link">My Batches</Link>
              </li>
              <li className="nav-item">
                <Link to="/batches2" className="nav-link">My Batches</Link>
              </li>
              <li className="nav-item">
                <Link to="/tickets" className="nav-link">My Tickets</Link>
              </li>
              <li className="nav-item">
                <Link to="/forms" className="nav-link">My Forms</Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <div className="container-fluid">
        <Switch>
          <Route path="/forms">
            <DDDDs />
          </Route>
          <Route path="/batches">
            <Batches />
          </Route>
          <Route path="/batches2">
            <BatchesNew />
          </Route>
          <Route path="/tickets">
            <Tickets />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
